.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1f1f38; /* Match your app's background color */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure loader appears on top of everything */
}

.loader {
  border: 8px solid #3498db; /* Blue border */
  border-radius: 50%;
  border-top: 8px solid #f0f0f0; /* Match your app's background color */
  width: 50px;
  height: 50px;
  animation: loader-animation 1s linear infinite; /* Rotate animation */
}

/* Customize loader animation */
@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
