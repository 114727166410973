header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}
/* Lubna Fahad Text */
.lubna-fahad {
  font-size: 2.5rem; /* Adjust the font size */
  font-weight: bold; /* Use bold font weight */
  font-family: "Arial Black", sans-serif; /* Use a bold font */
  color: #3498db; /* Set color to a vibrant blue (#3498db) */
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 2px; /* Add letter spacing for better readability */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow effect */
  background: linear-gradient(
    to right,
    #3498db,
    #ffffff
  ); /* Add a gradient background */
  background-clip: text; /* Clip text to background */
  -webkit-text-fill-color: transparent; /* Make text transparent */
}

/* Typewriter Animation for React Native Developer */
.typewriter {
  overflow: hidden; /* Hide overflow */
  white-space: nowrap; /* Keep text on one line */
  margin: 0 auto; /* Center text */
  letter-spacing: 0.15em; /* Adjust letter spacing */
  font-size: 1.2em; /* Adjust font size */
  animation: typing 3.5s infinite,
    /* Typing animation with infinite loop */ blink-caret 0.75s step-end
      infinite; /* Blinking cursor animation with infinite loop */
}

/* Typing Animation Keyframes */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Blinking Cursor Animation Keyframes */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

/* Adjust the font size */
.bigger-text {
  font-size: 1.5em;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ===============CTA================== */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/* ================HEADER SOCIALS============== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  font-size: 30px;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}
/* ====================Me============================ */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.me img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; /* Ensure the image doesn't exceed the container width */
  max-height: 100%; /* Ensure the image doesn't exceed the container height */
}

/* =============Scroll Down============== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  display: none;
}

/* ===========Media Queries for Tablet=========== */
@media screen and (max-width: 1024px) {
  header {
    height: 60vh;
  }
}
/* ===========Media Queries for Small Devices=========== */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}
